.boat-gallery {
  .card {
    overflow: hidden;
    .card-body {
      height: 265px!important;
      max-height: 265px;
      padding: .5rem!important;
      background-color: #f9f9f9;
      text-align: center; }
    .card-img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: calc(100% - 12px); } }
  @media (max-width: 991.98px) {
    .card {
      .card-body {
        height: 140px!important;
        max-height: 140px; } } }
  @media (max-width: 575.98px) {
    .card {
      .card-body {
        height: 120px!important;
        max-height: 120px; } } } }
