#order-form {
  margin-top: 126px;
  .form-container {
    height: calc(100vh - 126px);
    max-height: calc(100vh - 126px);
    overflow: hidden;
    overflow-y: scroll; } }

#order-form.with-notification {
  margin-top: 100px;
  .form-container {
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    overflow: hidden;
    overflow-y: scroll; } }

$gray: #f8f8f8;
$white: #fff;
$blue: #007bff;

.order-form {
  font-family: 'Nunito', sans-serif;
  &-tooltip {
    color: $blue;
    margin-left: 8px; }
  &-tooltip:hover {
    cursor: pointer;
    color: darken($blue, 10%); }
  &-input-group:not(:last-child) {
    border-bottom: 1px solid #dddddd; }
  &-input-group:last-child {
    padding-bottom: 80px!important; }
  &-page .card-body {
    height: calc(100vh - 349px);
    min-height: 375px;
    overflow-y: scroll;
    overflow-x: hidden; }
  @media (max-width: 575.98px) {
    &-page .card-body {
      height: calc(100vh - 259px); } } }

.order-form-tooltip {
  color: $blue;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px; }
