.order-form-page-nav {
  .list-group-item {
    padding: .5rem 1.25rem;
    .badge.badge-pill {
      float: right;
      margin-top: 2px; } }
  .list-group-item.active {
    .badge.badge-pill {
      background-color: #ffffff;
      color: #007bff; } } }
