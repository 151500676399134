.primary-option:hover {
  cursor: pointer;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important; }

.primary-option-header {
  padding-bottom: 1rem; }

.primary-img {
  background-color: #f8f8f8;
  min-height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 0;
  border-left: 0px;
  border-right: 0px; }
