.order-form input[type='checkbox'] {
  margin-top: .2rem; }
.order-form-checkbox.grid {
  font-size: .6rem;
  max-width: 80px;
  .form-check-input {
    margin-left: -1rem; }
  img {
    max-width: 50px;
    display: block;
    margin: auto; } }
