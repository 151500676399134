#review {
  height: calc(100vh - 349px);
  min-height: 375px;
  overflow-y: scroll;
  overflow-x: hidden; }
.review table {
  th:nth-child(1) {
    width: 56px; }
  th:nth-child(3) {
    max-width: 100px;
    width: 100px; } }
#review-page {
  margin-top: 1.5rem;
  .boat-gallery .card {
    margin-top: 2rem!important; } }

@media screen and (max-width: 400px) {
  .review {
    padding: .5rem 0px!important;
    .card-body {
      padding: 1rem .5rem 0 .5rem; } }
  .review table {
    margin-bottom: 0px; }
  .review table td {
    line-height: 1rem; }
  .review table td:nth-child(3) {
      max-width: 70px;
      width: 70px; } }
